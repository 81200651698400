/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ModelObject } from '../models';
import { OpportunitiesListDto } from '../models';
import { OpportunityDataDto } from '../models';
import { OpportunityDto } from '../models';
import { OpportunityPositionDto } from '../models';
import { OpportunityPositionListItemDto } from '../models';
import { UploadImageResponseDto } from '../models';

/**
 * OpportunityApi - axios parameter creator
 * @export
 */
export const OpportunityApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity: async (body: OpportunityDataDto, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerCreateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity: async (opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerDeleteOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetApprovedOpportunities: async (skip?: number, take?: number, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity/approved`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunities: async (skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (take !== undefined) {
        localVarQueryParameter['take'] = take;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (opportunityId) {
        localVarQueryParameter['opportunity_id'] = opportunityId;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunitiesPositions: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/market/opportunity/positions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunity: async (opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerGetOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions: async (body: Array<OpportunityPositionDto>, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunitiesPositions.');
      }
      const localVarPath = `/api/2.0/market/opportunity/positions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity: async (body: OpportunityDataDto, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUpdateOpportunity.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage: async (image: string, opportunityId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling opportunityControllerUploadImage.');
      }
      // verify required parameter 'opportunityId' is not null or undefined
      if (opportunityId === null || opportunityId === undefined) {
        throw new RequiredError('opportunityId', 'Required parameter opportunityId was null or undefined when calling opportunityControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/market/opportunity/{opportunity_id}/image`
        .replace(`{${'opportunity_id'}}`, encodeURIComponent(String(opportunityId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OpportunityApi - functional programming interface
 * @export
 */
export const OpportunityApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerCreateOpportunity(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunitiesListDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerGetApprovedOpportunities(skip, take, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunitiesListDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunitiesPositions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityPositionListItemDto>>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunitiesPositions(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerGetOpportunity(opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerGetOpportunity(opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpportunityPositionDto>>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpportunityDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await OpportunityApiAxiosParamCreator(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OpportunityApi - factory interface
 * @export
 */
export const OpportunityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Создать возможность заработать
     * @param {OpportunityDataDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any): AxiosPromise<OpportunityDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerCreateOpportunity(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Удалить возможность заработать
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerDeleteOpportunity(opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerDeleteOpportunity(opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать в статусе active
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any): AxiosPromise<OpportunitiesListDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerGetApprovedOpportunities(skip, take, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать
     * @param {number} [skip] Пропустить n записей
     * @param {number} [take] Количество записей в ответе
     * @param {string} [order] Порядок сортировки записей
     * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
     * @param {Array&lt;string&gt;} [status] Статус возможности заработать
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any): AxiosPromise<OpportunitiesListDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список возможностей заработать для сортировки
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunitiesPositions(options?: any): AxiosPromise<Array<OpportunityPositionListItemDto>> {
      return OpportunityApiFp(configuration)
        .opportunityControllerGetOpportunitiesPositions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить возможность заработать (по id)
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerGetOpportunity(opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerGetOpportunity(opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить сортировку списка возможностей заработать
     * @param {Array&lt;OpportunityPositionDto&gt;} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any): AxiosPromise<Array<OpportunityPositionDto>> {
      return OpportunityApiFp(configuration)
        .opportunityControllerUpdateOpportunitiesPositions(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Изменить возможность заработать
     * @param {OpportunityDataDto} body
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any): AxiosPromise<OpportunityDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerUpdateOpportunity(body, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузка изображения
     * @param {string} image
     * @param {number} opportunityId Идентификатор
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    opportunityControllerUploadImage(image: string, opportunityId: number, options?: any): AxiosPromise<UploadImageResponseDto> {
      return OpportunityApiFp(configuration)
        .opportunityControllerUploadImage(image, opportunityId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OpportunityApi - object-oriented interface
 * @export
 * @class OpportunityApi
 * @extends {BaseAPI}
 */
export class OpportunityApi extends BaseAPI {
  /**
   *
   * @summary Создать возможность заработать
   * @param {OpportunityDataDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerCreateOpportunity(body: OpportunityDataDto, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerCreateOpportunity(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Удалить возможность заработать
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerDeleteOpportunity(opportunityId: number, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerDeleteOpportunity(opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать в статусе active
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerGetApprovedOpportunities(skip?: number, take?: number, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerGetApprovedOpportunities(skip, take, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать
   * @param {number} [skip] Пропустить n записей
   * @param {number} [take] Количество записей в ответе
   * @param {string} [order] Порядок сортировки записей
   * @param {Array&lt;ModelObject&gt;} [opportunityId] Идентификатор возможности заработка
   * @param {Array&lt;string&gt;} [status] Статус возможности заработать
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerGetOpportunities(skip?: number, take?: number, order?: string, opportunityId?: Array<ModelObject>, status?: Array<string>, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerGetOpportunities(skip, take, order, opportunityId, status, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список возможностей заработать для сортировки
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerGetOpportunitiesPositions(options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerGetOpportunitiesPositions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить возможность заработать (по id)
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerGetOpportunity(opportunityId: number, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerGetOpportunity(opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить сортировку списка возможностей заработать
   * @param {Array&lt;OpportunityPositionDto&gt;} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerUpdateOpportunitiesPositions(body: Array<OpportunityPositionDto>, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerUpdateOpportunitiesPositions(body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Изменить возможность заработать
   * @param {OpportunityDataDto} body
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerUpdateOpportunity(body: OpportunityDataDto, opportunityId: number, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerUpdateOpportunity(body, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузка изображения
   * @param {string} image
   * @param {number} opportunityId Идентификатор
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OpportunityApi
   */
  public opportunityControllerUploadImage(image: string, opportunityId: number, options?: any) {
    return OpportunityApiFp(this.configuration)
      .opportunityControllerUploadImage(image, opportunityId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
